<template>
  <div class="login-style contentArea main-template">
    <div class="cus-position">
      <div class="cus-w mx-auto">
        <div class="flex justify-center">
          <img src="@/assets/images/logo/Central.png" />
        </div>
        <br />
        <div class="flex justify-center">
          <h1 class="t-color">Admin</h1>
        </div>

        <br />
        <form @submit.prevent="LoginAdmin($event)">
          <vs-input
            placeholder="username"
            icon="people"
            class="w-full logininput"
            type="text"
            v-model="username"
            required
          />
          <br />
          <br />
          <vs-input
            placeholder="password"
            icon="lock"
            type="password"
            v-model="password"
            class="w-full logininput"
            required
          />
          <br />

          <!-- <a class="forgetpass" href=">">ลืมรหัสผ่าน?</a> -->
          <br /><br />
          <div class="flex justify-center">
            <button class="login-btn">Log in</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../../util/backend";
import { baseurl, catalogBaseurl, stage } from "../../util/baseUrl";
export default {
  data() {
    return {
      username: null,
      password: null,
    };
  },
  mounted() {
    console.log("this.$store", this.$store);
  },
  methods: {
    async LoginAdmin() {
      this.$vs.loading({ color: "#DDDDDD" });
      await axios
        .post(`${catalogBaseurl()}/api/admin/loginAdmin`, {
          username: this.username,
          password: this.password,
        })
        .then(async (res) => {
          axios.defaults.headers.common["Authorization"] = res.data.jwt;
          localStorage.setItem("token", res.data.jwt);
          // localStorage.setItem("refresh_token", res.data.RefreshToken);
          await this.$store.commit("general/COLLECT_TOKEN", {
            token: res.data.token,
            // refresh_token: res.data.RefreshToken,
          });
          await this.$store.commit("general/GET_ADMIN_INFO", res.data.username);
          this.$vs.loading.close();
          // this.$store.dispatch("general/push_activePaage", {
          //   url : "/",
          //   name: "รายชื่อผู้ติดต่อ",
          //   slug: "campaign"
          // })
          if (stage() === "dev") {
            this.$router.push({ path: "/catalog" });
          } else {
            this.$router.push({ path: "/" });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "error message",
            text: `${
              err.response.data.message ? err.response.data.message : err
            }`,
            color: "danger",
            icon: "error",
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.forgetpass {
  font-size: 1.2rem;
  background: -webkit-linear-gradient(#007afe, #013f81);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: end;
}

.login-style {
  // background-image: url("../../../src/assets/images/bg/AdminBG.png");
  // background-size: cover;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.cus-position {
  width: 90%;
  padding: 10rem 0 10rem 0;
  background-color: rgba(255, 255, 255, 0.432);
}

.cus-w {
  width: 30%;
}

.input-style {
  border: none;
  border-radius: 8px;
  background-color: #ededed;
}

.login-btn {
  font-size: 1.2rem;
  padding: 0.5rem 3rem;
  background-color: #92a3b7;
  color: white;
  border-radius: 15px;
  border: none;
}

.logininput {
  color: #434343 !important;
}

.logininput .vs-icon {
  line-height: 1.6 !important;
  padding-left: 0.5rem;
  border: none;
}

.logininput .vs-input--placeholder {
  color: #434343 !important;
}

.logininput .vs-con-input .vs-inputx {
  background-color: #ededed;
  border: none !important;
}

.t-color {
  color: rgba(29, 56, 133, 0.5) !important;
}
.main-template {
  background: linear-gradient(180deg, #92a3b7 0%, #c2d7e5 46.35%, #6a7e91 100%);
  background-size: cover;
}
</style>
